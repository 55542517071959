<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="isLoading" class="mt-5">
    <PagePreloader layout="list"/>
  </div>
  <div v-if="inquiries">
    <div class="add-property-title mb-4"> {{ $t('agency-viewing-requests-title') }}</div>
    <div class="mb-3" v-for="inquiry in inquiries">
      <ViewingInquiry :name="inquiry.contactName"
                      :phone="inquiry.contactPhone"
                      :email="inquiry.contactEmail"
                      :date="inquiry.dateTime"
                      :time="inquiry.time"
                      :propertyid="inquiry.property.id"
                      :title="inquiry.property.title"
                      :image="inquiry.property.images.length > 0 ? inquiry.property.images[0].src : ''"
                      :labelnew="inquiry.labelnew"/>
    </div>
    <Pagination
      :size="'small'"
      :pages="Math.ceil(totalInquiries/limit)"
      :route="'ViewingRequests'"
      :current="currentPage"
      @pageChanged="handlePageChange"
    />
  </div>
</template>
